import nasaSFAD from "../assets/SFAD-2021-National-Coord.jpg";
import bookHistory from "../assets/Screenshot 2022-11-01 at 20.04.13.png";
import outreach from "../assets/outreach.jpg";
import research from "../assets/Screenshot 2024-01-23 at 10.16.22.png";
import iac23 from "../assets/Ankit_IAC2023 (1)_page-0001.jpg";
import coy18 from "../assets/Green Colorful Climate Change Prevention Branding LinkedIn Banner (18 x 12 in) (2).png";

export const data = [{
    projectTitle: "DATA ETHICS TOOLKIT A Toolkit for Data Ethics in the Participatory Sciences",
    projectBody: "Developed toolkit for data ethics in the participatory sciences.",
    picture: "https://orrery-media.s3.amazonaws.com/uploads/froala_editor/images/Data%20Ethics%20Toolkit%20thumbnail.png",
    link: "https://participatorysciences.org/resources/data-ethics/",
},{
    projectTitle: "NASA Scientist for a day",
    projectBody: "Scientist for a Day was an essay contest that gave students in grades 5-12 a chance to think like scientists and learn about real NASA missions. Various NASA missions and projects have sponsored this essay contest over the years. It was held annually from 2006-2021.",
    picture: nasaSFAD,
    link: "https://rps.nasa.gov/scientist-for-a-day/international/",
},{
    projectTitle: "Research Papers",
    projectBody: "Here are my all the research papers till date.",
    link: "https://www.researchgate.net/profile/Ankit-Khanal-4",
    picture: research,
},{
    projectTitle: "Book on Space History around the World",
    projectBody: "As a Committee Member of Advisory Committee on History Activities at International Astronautical Federation(IAF) I have been envolved in writing a book on Space History of World",
    picture: bookHistory,
    link: "https://www.iafastro.org/about/iaf-committees/administrative-committees/iaf-iaa-iisl-advisory-committee-on-history-activities-acha.html",
},{
    projectTitle: "Outreach in Community School of Nepal",
    projectBody: "",
    picture: outreach,
    link: "https://my.astronomerswithoutborders.org/blogs/ankit-khanal1/2023/05/20/outreach-in-community-school-of-nepal",
},{
    projectTitle: "IAC 2023",
    projectBody: "",
    picture: iac23,
    link: "https://acem.edu.np/news/details/congratulations-mr-ankit-khanal-108/",
},
{
    projectTitle: "COY18",
    projectBody: "",
    picture: coy18,
    link: "https://acem.edu.np/news/details/congratulations-mr-ankit-khanal-118/",
}
]


