import React from "react";
import "./aboutMe.css";
const AboutMe = () => {
  return (
    <div className="wrapper">
      <h1>My Blogs</h1>
      <p>I am a Computer Engineer.</p>
      <p style={{marginTop: "50px", fontSize: 14, padding: "50px", textAlign: "justify", lineHeight: "2em"}}>For 7 years, I've passionately pursued my dream as a Nepali space enthusiast, aspiring to explore extraterrestrial life and become an astronaut. In the Asia-Pacific space sector, I've contributed as a Committee Member of the IAA/IAF/IISL Advisory Committee on History Activities (ACHA), writing a book on World Space History. As the Nation Coordinator of Astronomers Without Borders (AWB) for Nepal, I actively promote space education, nurturing young students' curiosity. As a Research Analyst for the Asia Pacific Oceania Space Association (APOSA), I address security and political issues surrounding Earth observation data sharing through the London Politica project.
Inspiring the next generation is my heartfelt dedication, evident as the Nation Coordinator for NASA's Scientist for a Day program. Holding esteemed titles like Dark Skies Ambassador, STEM Ambassador, and Student Space Ambassador, I share my passion with diverse communities. As an operational manager at NESRA, I drive Nepal's space industry growth, aiming to transform our nation into a space-faring one. Space is my driving force, and I'm committed to making a positive impact and inspiring others to explore and discover the wonders of space.

</p>
    </div>
  );
};

export default AboutMe;
